<template>
  <zbm-async-content class="col" :loading="isLoading">
    <template v-slot:loading>
      <q-skeleton type="rect" width="100%" height="36px" class="mb-24" />
      <q-skeleton type="rect" width="250px" height="26px" class="mb-40" />

      <template v-for="i in 2" :key="i">
        <q-skeleton type="rect" width="100px" height="16px" class="mb-12" />
        <q-skeleton type="rect" width="100%" height="220px" class="mb-24" />
      </template>
    </template>
    <SubNavigationLayout :class="'the-dashboard-my-stats'" title="stats">
      <template v-slot:more-info>
        <div class="col-auto pl-0" v-if="showPIQ">
          <zbm-btn-icon icon="fal fa-question-circle" @click="onQuestionClick" size="26px"
            :buttonHeight="$zb.enums.ButtonHeightEnum.lg" class="text-dark-teal" color="white" />
        </div>
      </template>

      <template v-slot:navigation>
        <div class="col-auto">
          <!-- Dropdown -->
          <zbm-dropdown v-model="selectedNavItem" class="font-size-14" :options="subNavItems" omitDefaultOption v-if="sources.length" />
        </div>
      </template>

      <template v-slot:content>
        <template v-if="selectedNavItem">
          <component :is="componentName" :ready-signal="triggerGraphs" :device-id="selectedNavItem.eocDeviceId" />
        </template>
        <template v-else-if="!hasPiq || !sources.length">
          <p>{{ $t('my_stats_no_access') }}</p>
        </template>
      </template>
    </SubNavigationLayout>
  </zbm-async-content>
</template>

<script setup lang="ts">
import { ref, computed, watch, nextTick, onActivated } from 'vue';
import { useRoute } from 'vue-router';
import { useDashboardStore, useGeneralPurposeModalStore, useSessionStore } from 'src/store';
import { getPatientDataSources } from 'src/api/endpoints/statisticsWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { type DataSourceResponse } from 'src/types/webContracts';
import SkeletonLoader from 'src/components/SkeletonLoader.vue';
import SubNavigationLayout from 'src/components/layouts/SubNavigationLayout.vue';
import { type DropdownItem } from 'src/components/PWEDropdown.vue';
import { useQuasar } from 'quasar';
import { GeneralPurposeVariant } from '@/constants/enums';
import { i18n } from '@/plugins/i18n';
import { reformatString } from '@/utils/stringUtils';
import ThePersonaIQMyStats from './ThePersonaIQMyStats.vue';
import { StatTypeEnum } from '@/constants/measurementTypes';

const $q = useQuasar()
const sessionStore = useSessionStore();
const dashboardStore = useDashboardStore()
const generalPurposeModalStore = useGeneralPurposeModalStore()
const route = useRoute();

const props = defineProps({
  sourceType: String
})

const sources = ref<DataSourceResponse[]>([]);
const isFetchingSources = ref<boolean>(false);
const selectedNavItem = ref<DropdownItem>();
const triggerGraphs = ref(0)

const showPIQ = computed(() => {
  return props.sourceType === StatTypeEnum.PersonaIQ
});

const componentName = computed(() => {
  switch (props.sourceType) {
    case StatTypeEnum.PersonaIQ: return ThePersonaIQMyStats
  }
})

const onQuestionClick = () => {
  let title, template

  switch (props.sourceType) {
    case StatTypeEnum.PersonaIQ:
      title = i18n.global.t('stats_info').toString();
      template = reformatString(i18n.global.t('piq_pwe_pme_information_modal').toString());
      break
  }


  generalPurposeModalStore.setModal({
    variant: GeneralPurposeVariant.Information,
    title,
    template
  });
}

const subNavItems = computed(() => {
  const array: Array<DropdownItem> = [];

  if (sources.value !== null) {
    sources.value.forEach(function (source) {
      array.push({
        label: source.localizedName,
        value: source.eocId,
        eocDeviceId: source.eocDeviceId,
        sourceType: source.sourceType,
      });
    });
  }

  return array;
});

const isLoading = computed(() => {
  return !dashboardStore.patient || isFetchingSources.value;
});

watch(() => selectedNavItem.value, (nV) => {
  nextTick(() => triggerGraphs.value++)
})

onActivated(async () => {
  if (!selectedNavItem.value) {
    isFetchingSources.value = true;

    const dataSources = await handleApiRequest(() => getPatientDataSources(), null, $q);

    if (dataSources?.result && dataSources?.result.dataSources) {
      sources.value = dataSources.result.dataSources.filter(p => p.sourceType !== 'Wearable');

      sources.value = sources.value.sort((a, b) =>
        a.eocId === b.eocId
          ? (a.eocDeviceId || 0) - (b.eocDeviceId || 0)
          : (b.eocId || 0) - (a.eocId || 0)
      );
    }
  }

  const navItemFromRoute = subNavItems.value.find(
    (p) => p.eocDeviceId == route.params.eocDeviceId
  );

  const navItemFromEOC = subNavItems.value.find((s) => s.eocId === sessionStore.user?.selectedEocId);

  selectedNavItem.value = navItemFromRoute || navItemFromEOC || subNavItems.value[0];

  isFetchingSources.value = false;
})
</script>