<template>
  <div class="col">
    <zbm-async-content :loading="isLoading">
      <template v-slot:loading>
        <div class="row">
          <div class="col-auto pr-16 pb-16">
            <q-skeleton type="rect" width="100px" height="30px" />
          </div>
          <div class="col-auto pr-16">
            <q-skeleton type="rect" width="100px" height="30px" />
          </div>
          <div class="col-12 pb-24">
            <q-skeleton type="rect" width="100%" height="4px" />
          </div>
          <div class="col-12">
            <q-skeleton type="rect" width="100%" height="400px" />
          </div>
        </div>
      </template>
      <SubNavigationLayout :class="'the-dashboard-education'" :title="'gen_education'" :type="'tab'" :navItems="tabNav">
        <template v-slot:more-info>
          <div class="col-auto pl-0">
            <zbm-btn-icon icon="fal fa-question-circle" @click="onQuestionClick" size="26px"
              :buttonHeight="$zb.enums.ButtonHeightEnum.lg" class="text-dark-teal" color="white" />
          </div>
        </template>
      </SubNavigationLayout>
    </zbm-async-content>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useDashboardStore, useGeneralPurposeModalStore } from 'src/store';
import SubNavigationLayout from 'src/components/layouts/SubNavigationLayout.vue';
import { i18n } from '@/plugins/i18n';
import { type DropdownItem } from 'src/components/PWEDropdown.vue';
import { GeneralPurposeVariant } from '@/constants/enums';
import { reformatString } from '@/utils/stringUtils';

const generalPurposeModalStore = useGeneralPurposeModalStore()
const dashboardStore = useDashboardStore()

const tabNav = computed(() => {
  const rawTabNav = [
    {
      value: 1,
      label: i18n.global.t('education_pre_surgery'),
      path: 'Dashboard.Education.PreSurgery',
      disabled: false
    },
    {
      value: 2,
      label: i18n.global.t('education_post_surgery'),
      path: 'Dashboard.Education.PostSurgery',
      disabled: false
    },
    {
      value: 3,
      label: i18n.global.t('rosa'),
      path: 'Dashboard.Education.Technology',
      disabled: false
    },
  ] as DropdownItem[];
  const t = rawTabNav.sort((a, b) => a.value - b.value);

  if (dashboardStore.patient && !dashboardStore.patient.showRosaEducation) {
    return t.filter(o => o.value !== 3);
  }

  return t;
});

const isLoading = computed(() => {
  return !dashboardStore.patient;
});

const kvp = computed(() => {
  if (dashboardStore.patient?.showRosaEducation) {
    return 'rosa_help_content';
  }
  return 'education_help'
});

function onQuestionClick() {
  generalPurposeModalStore.setModal({
    variant: GeneralPurposeVariant.Information,
    title: i18n.global.t('education_info').toString(),
    template: reformatString(i18n.global.t(kvp.value).toString())
  });
}
</script>