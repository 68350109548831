<script lang="ts" setup>
import { useQuasar } from 'quasar';
import { useRouter } from 'vue-router';
import {
  useDashboardStore,
  useMessagesStore,
  useSessionStore
} from 'src/store';
import {
  computed,
  onActivated,
  onMounted,
  watchEffect
} from 'vue';
import { loadPatientInfoAndTasks } from 'src/pages/TheDataHydrator';
import Box from 'src/components/BoxContainer.vue';
import Navigation from 'src/components/DashboardNavigation.vue';
import DashboardNotification from 'src/components/DashboardNotifications.vue';

const $q = useQuasar(),
  router = useRouter();

const dashboardStore = useDashboardStore(),
  messagesStore = useMessagesStore(),
  sessionStore = useSessionStore();

const alerts = computed(() => dashboardStore.alerts?.filter(x => x.visible) || []),
  isLoading = computed(() => dashboardStore.isLoading),
  mandatoryPainRatingCheckin = computed(() => dashboardStore.patient?.painAndOpioidData?.checkinRequired),
  unreadMsgCt = computed(() => messagesStore.unreadMessagesCount);

onActivated(async () => {
  await loadPatientInfoAndTasks(sessionStore, messagesStore, dashboardStore, $q);
  dashboardStore.setTodaysDate();

})

watchEffect(() => mandatoryPainRatingCheckin,
  (newVal, oldVal) => {
    if (newVal) {
      router.push({ name: 'PainRating' });
    }
  }
);
</script>

<template>
  <q-page class="dashboard" padding>
    <div class="row bs-gutter justify-center">

      <!-- Box -->
      <Box :box-padding="$zb.enums.BoxPaddingEnum.p303055" :box-width="$zb.enums.BoxWidthEnum.xl"
        class="col col-md-auto">
        <div class="row">

          <!-- Left Column -->
          <div class="col-auto gt-sm dashboard__left">

            <!-- Navigation -->
            <div class="row dashboard__navigation">
              <div class="col">
                <Navigation :unread-msg-ct="unreadMsgCt" />
              </div>
            </div>

            <!-- Auxiliary -->
            <div class="row pt-30">
              <div class="col">
                <router-view v-slot="{ Component }" name="auxiliary">
                  <keep-alive>
                    <component :is="Component" />
                  </keep-alive>
                </router-view>
              </div>
            </div>
          </div>

          <!-- Right Column -->
          <div class="col dashboard__right">

            <!-- Notifications -->
            <div v-if="alerts" class="row dashboard__notif">
              <div class="col">
                <zbm-skeleton-loader class="mb-30" height="48px" :loading="isLoading" width="100%">
                  <DashboardNotification v-for="(alert, i) in alerts" @dismiss-notif="(value) => value.visible = false"
                    :alert="alert" :key="`banner-${i}`" />
                </zbm-skeleton-loader>
              </div>
            </div>

            <!-- Default -->
            <div class="row">
              <div class="col">
                <router-view v-slot="{ Component }">
                  <keep-alive>
                    <component :is="Component" />
                  </keep-alive>
                </router-view>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
.dashboard {
  $dash-brdr: 1px solid $border-clr;

  // Left Column (only visible at md, 1024px, breakpoint and wider)
  &__left {
    // [class*="col"]
    $dash-left-col-w: 248px + 30px + 1px; // content space + right padding + border width

    width: $dash-left-col-w !important;
    max-width: $dash-left-col-w !important;
    flex-basis: $dash-left-col-w;
    padding-inline-end: 30px;
    border-inline-end: $dash-brdr;
  }

  // Navigation
  &__navigation {
    // .row
    padding-block-end: 22px;
    border-block-end: $dash-brdr;
  }

  // Notifications
  &__notif>[class*="col"] {

    :deep(> div:empty:not(.q-skeleton)) {
      margin-block-end: 0 !important;
    }
  }

  @media (min-width: $breakpoint-md-min) {

    // Right Column
    &__right {
      padding-inline-start: 30px;
    }
  }
}
</style>