
import { Ref, computed, ref } from 'vue'
import { defineStore } from 'pinia'
import type {
  PatientProfileData,
  Alert,
  Task,
  PatientStatisticsResponse,
  PatientTasksResult,
  PatientInterrupt
} from 'src/types/webContracts';
import { toUserFacingDate } from 'src/utils/dateUtil';
import { TaskType } from 'src/constants/enums';
import { useLocalizationStore } from 'src/store/localizationModule';
import { type UserPatientResponse } from 'src/types/webContracts'

export const useDashboardStore = defineStore('dashboard', () => {
  const patient: Ref<PatientProfileData | null> = ref(null);
  const patientConfig: Ref<UserPatientResponse | null> = ref(null)
  const alerts: Ref<Alert[] | null> = ref(null);
  const tasks: Ref<Task[] | null> = ref(null);
  const statisticSummaries: Ref<Record<string, PatientStatisticsResponse | null>> = ref({});
  const nextEducationDate: Ref<string | null> = ref(null);
  const nextSurveyDate: Ref<string | null> = ref(null);
  const nextRoutineDate: Ref<string | null> = ref(null);
  const todaysDate = ref('');
  const isLoading = ref(false);
  const skipRoutineSafetyDisclaimerModal = ref(false);
  const patientInterrupts: Ref<PatientInterrupt[]> = ref([]);

  const clear = () => {
    patient.value = null;
    patientConfig.value = null;
    alerts.value = null;
    tasks.value = null;
    statisticSummaries.value = {};
    nextEducationDate.value = null;
    nextSurveyDate.value = null;
    nextRoutineDate.value = null;
    todaysDate.value = '';
    isLoading.value = false;
    skipRoutineSafetyDisclaimerModal.value = false;
    patientInterrupts.value = [];
  }

  const setIsLoading = (isLoadingParam: boolean) => {
    isLoading.value = isLoadingParam;
  }

  const setPatientFields = (pat: PatientProfileData | null) => {
    patient.value = pat ? { ...pat } : null;
  }

  const setPatientConfig = (config: UserPatientResponse | null) => {
    patientConfig.value = config ? { ...config } : null;
  }

  const setAlerts = (alertsParam: Array<Alert>) => {
    alerts.value = alertsParam.map((item) => ({
      ...item,
      visible: true
    }));
  }

  const setTasks = (tasksParam: Array<Task>) => {
    tasks.value = tasksParam;
  }

  const setNextEducationDate = (date: string) => {
    const localizationModule = useLocalizationStore();
    nextEducationDate.value = toUserFacingDate(date, false, localizationModule.locale);
  }

  const setNextRoutineDate = (date: string) => {
    const localizationModule = useLocalizationStore();
    nextRoutineDate.value = toUserFacingDate(date, false, localizationModule.locale);
  }

  const setNextSurveyDate = (date: string) => {
    const localizationModule = useLocalizationStore();
    nextSurveyDate.value = toUserFacingDate(date, false, localizationModule.locale);
  }

  const setStatisticSummaries = ({ patientStats, sourceKey }: { patientStats: PatientStatisticsResponse, sourceKey: string }) => {
    statisticSummaries.value[sourceKey] = patientStats;
  }

  const markTaskCompleted = ({ id, taskType }: { id: number, taskType: TaskType }) => {
    const update = tasks.value?.find(x => x.taskType == taskType && x.id === id);
    if (update) {
      update.isComplete = true;
    }
  }

  const setPatientInterrupts = (interrupts: PatientInterrupt[]) => {
    patientInterrupts.value = interrupts
  }

  const removeTask = ({ id, taskType }: { id: number, taskType: TaskType }) => {
    tasks.value = tasks.value?.filter(x => !(x.taskType === taskType && x.id === id)) ?? null;
  }

  const setSkipRoutineSafetyDisclaimerModal = (skipRoutineSafetyDisclaimerModalParam: boolean) => {
    skipRoutineSafetyDisclaimerModal.value = skipRoutineSafetyDisclaimerModalParam;
  }

  const mostRecentCompletedTime = computed(() => {
    const minimumTimeForNextRoutine = 60;
    //sort all the routines
    let minutesSinceMostRecentCompletedRoutine = minimumTimeForNextRoutine
    if (tasks.value) {
      const routines = tasks.value?.filter((item) => {
        return item.taskType === TaskType.routine && item.isComplete === true;
      });

      if (routines.length) {
        // Get the time since the last completed routine
        // 1. Filter out everything that doesn't have a completed time
        // 2. Map into an array of numbers
        // 3. Sort ascending
        // 4. Get the the first value if one exists otherwise return the minimum time for the next routine  
        minutesSinceMostRecentCompletedRoutine = routines
          .filter(x => x.minutesSinceCompleted != null)
          .map(x => x.minutesSinceCompleted as number).sort((a, b) => {
            return a - b;
          }).find(o => o) ?? minimumTimeForNextRoutine;
      }
    }
    return minutesSinceMostRecentCompletedRoutine;
  })

  const loadPatient = (patient: PatientProfileData) => {
    setPatientFields(patient);
  }

  const loadPatientConfig = (config: UserPatientResponse) => {
    setPatientConfig(config);
  }

  const loadAlerts = (alerts: Array<Alert>) => {
    setAlerts(alerts);
  }

  const loadTasks = (tasks: Array<Task>) => {
    setTasks(tasks);
  }

  const loadNextTaskDates = (result: PatientTasksResult) => {
    setNextEducationDate(result.nextEducation);
    setNextRoutineDate(result.nextRoutine);
    setNextSurveyDate(result.nextSurvey);
  }

  const loadPatientWearables = (statisticSummaries: PatientStatisticsResponse) => {
    setStatisticSummaries({ patientStats: statisticSummaries, sourceKey: 'Wearable' });
  }

  const setTodaysDate = (locale = navigator.languages[0]) => {
    if (todaysDate.value.length == 0) {
      const date = new Date(),
        options: Intl.DateTimeFormatOptions = {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        };
      todaysDate.value = date.toLocaleDateString(locale, options);
    }
  }

  const loadPatientInterrupts = (interrupts: PatientInterrupt[]) => {
    setPatientInterrupts(interrupts)
  }

  return {
    patient,
    patientConfig,
    alerts,
    tasks,
    statisticSummaries,
    nextEducationDate,
    nextSurveyDate,
    nextRoutineDate,
    todaysDate,
    isLoading,
    skipRoutineSafetyDisclaimerModal,
    patientInterrupts,

    clear,
    setIsLoading,
    setPatientFields,
    setPatientConfig,
    setAlerts,
    setTasks,
    setNextEducationDate,
    setNextRoutineDate,
    setNextSurveyDate,
    setStatisticSummaries,

    markTaskCompleted,
    removeTask,
    setSkipRoutineSafetyDisclaimerModal,
    mostRecentCompletedTime,
    loadPatient,
    loadPatientConfig,
    loadAlerts,
    loadTasks,
    loadNextTaskDates,
    loadPatientWearables,
    setTodaysDate,
    loadPatientInterrupts
  }
})