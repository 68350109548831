<template>
  <div id="password-input">
    <!-- Initial Password Field -->
    <zbm-input-with-validation :autofocus="props.autoFocus" v-model="password" v-on:keyup.enter="onSubmit"
      @update:modelValue="validate" vid="password" :type="!showPassword ? 'password' : 'text'" ref="passwordInput"
      :name="$t(initialLabel)" :minLength="hasPin ? 4 : undefined" :maxLength="hasPin ? 4 : undefined"
      :placeholder="$t(initialPlaceholder)" :class="passwordRulesValid ? '' : 'q-field--error'" :mask="mask">
      <template v-slot:append>
        <q-icon @click="showPassword = !showPassword" class="cursor-pointer"
          :name="showPassword ? eyeClosedIcon : eyeOpenedIcon" size="20px" />
      </template>
    </zbm-input-with-validation>
    <ValidationList v-model="passwordRules" />
  </div>
  <div>
    <!-- Confirmation Password Field -->
    <zbm-input-with-validation v-model="confirmPassword" v-on:keyup.enter="onSubmit" @update:modelValue="validate"
      :type="!showConfirmPassword ? 'password' : 'text'" :customMessages="{ required: $t('password_empty_confirm') }"
      :name="$t(confirmationLabel)" :minLength="hasPin ? 4 : undefined" :maxLength="hasPin ? 4 : undefined"
      :placeholder="$t(confirmationPlaceholder)" :class="confirmPasswordRulesValid ? '' : 'q-field--error'"
      :mask="mask">
      <template v-slot:append>
        <q-icon @click="showConfirmPassword = !showConfirmPassword" class="cursor-pointer"
          :name="showConfirmPassword ? eyeClosedIcon : eyeOpenedIcon" size="20px" />
      </template>
    </zbm-input-with-validation>
    <ValidationList v-model="confirmPasswordRules" />
  </div>
</template>

<style lang="scss" scoped>
#password-input {
  padding-bottom: 30px;
}
</style>

<script lang="ts" setup>
import { onMounted, ref, type PropType, defineEmits, reactive } from 'vue';
import { type PasswordConfirmWithValidationResult } from 'src/utils/componentFactories';
import { nextTick } from 'vue';
import { hasLowercase, hasNum, hasUppercase, hasValidSpecialCharacter } from 'src/plugins/regex-functions';
import * as Validators from '@/plugins/quasar-validators'
import { i18n } from '@/plugins/i18n';
import ValidationList, { type ValidationListRule } from 'src/components/form/ValidationList.vue';
import { type ValidationRule } from 'quasar';

export interface PasswordConfirmWithValidationComponent {
  reset(): void;
}

const $t = i18n.global.t;

const props = defineProps({
  initialLabel: {
    type: String,
    default: '',
  },
  initialPlaceholder: {
    type: String,
    default: 'activate_username_create',
  },
  confirmationLabel: {
    type: String,
    default: '',
  },
  confirmationPlaceholder: {
    type: String,
    default: '',
    required: true,
  },
  hasPin: {
    type: Boolean,
    default: false,
  },
  syncValue: {
    type: Object as PropType<PasswordConfirmWithValidationResult>,
    default: () => ({}),
  },
  autoFocus: {
    type: Boolean,
    default: false,
  },
});

const password = ref('');
const confirmPassword = ref('');
const showPassword = ref(false);
const showConfirmPassword = ref(false);
const emit = defineEmits(['update:modelValue', 'validPassword', 'validate']);

const eyeClosedIcon = 'far fa-eye-slash';
const eyeOpenedIcon = 'far fa-eye'

const passwordRules = reactive([] as Array<ValidationListRule>);
const confirmPasswordRules = reactive([] as Array<ValidationListRule>);

const passwordRulesValid = ref(true);
const confirmPasswordRulesValid = ref(true);

const mask = ref();

function initializeRules() {
  passwordRules.value = [];
  if (props.hasPin) {
    passwordRules.push(
      { rule: Validators.required(), displayText: $t('activate_passcode_error_title') },
      { rule: Validators.betweenLen(4, 4), displayText: $t('pwe_passcode_error_digits') },
      { rule: Validators.number(), displayText: $t('pwe_passcode_error_only_numbers') },
      // This is a dummy rule that just checks that the other 3 rules are valid
      { rule: Validators.dummyRule(), displayText: $t('password_requirements_not_met') },

    );

    confirmPasswordRules.push(
      { rule: Validators.required(), displayText: $t('pwe_passcode_error_required') },
      { rule: Validators.equals(), displayText: $t('passcodes_match') },
    );
    mask.value = '####';
  }
  else {
    passwordRules.push(
      { rule: Validators.required(), displayText: $t('password_empty') },
      { rule: Validators.minLen(8), displayText: $t('password_requirement_min_8char_ios') },
      { rule: hasUppercase, displayText: $t('password_requirement_1uppercase_ios') },
      { rule: hasLowercase, displayText: $t('password_requirement_1lowercase_ios') },
      { rule: hasNum, displayText: $t('activate_reqs_one_number') },
      { rule: hasValidSpecialCharacter, displayText: $t('activate_reqs_one_spec_char') },
      { rule: Validators.omitsInvalidSpecialCharacter(), displayText: $t('password_requirements_invalid_chars_ios', ['{}']) },
      { rule: Validators.dummyRule(), displayText: $t('password_requirements_not_met') },
    );
    confirmPasswordRules.push(
      { rule: Validators.required(), displayText: $t('password_empty_confirm') },
      { rule: Validators.equals(), displayText: $t('passwords_match') },
    );
  }
}

onMounted(() => {
  initializeRules()
})


function validate() {
  nextTick(() => {
    const isValid = validatePasswordInputs();
    emit('validate', {
      password: password.value,
      confirmPassword: confirmPassword.value,
      isValid: isValid
    } as PasswordConfirmWithValidationResult);
  });
}

function validatePasswordInputs() {
  passwordRules.forEach((rule) => {
    rule.isValid = validateRule(rule.rule, password.value);
  });
  confirmPasswordRules.forEach((rule) => {
    rule.isValid = validateRule(rule.rule, confirmPassword.value);
  });

  passwordRulesValid.value = passwordRules.every((rule) => rule.isValid);
  if (!passwordRulesValid.value) {
    const allValidRule = passwordRules.find(rule => rule.rule.toString() == Validators.dummyRule().toString());
    if (allValidRule) {
      allValidRule.isValid = false;
    }
  }
  confirmPasswordRulesValid.value = confirmPasswordRules.every((rule) => rule.isValid);

  return passwordRulesValid && confirmPasswordRulesValid;
}

function validateRule(rule: ValidationRule, value: string) {
  if (rule.toString() == Validators.equals().toString()) {
    return Validators.equals(password.value)(value) === true;
  }
  else if (rule.toString() == Validators.dummyRule().toString()) {
    return true;
  }
  return rule(value) === true;
}

async function onSubmit() {
  const isValid = validatePasswordInputs();
  if (isValid) {
    onValidPassword();
  }
}

function onValidPassword() {
  emit('validPassword', props.syncValue);
}

</script>