import {
  OnSlideEnum,
  QuestionCategoryResponseEnum,
  SliderOrientationEnum,
  SliderValueBehaviorEnum,
  SurveyComponentQuestionTypeEnum,
  SurveyTypeEnum
} from 'src/constants/survey';
import {
  SurveyQuestionModel,
} from 'src/types/model';
import {
  SurveyDefinitionWrapperResponse,
  SurveyPermittedAnswerResponse,
  SurveyQuestionResponse
} from 'src/types/webContracts';

import { toSurveyPermittedAnswerModel } from './surveyPermittedAnswerAdapter'

import { i18n } from 'src/plugins/i18n'
import { maxVal, minVal } from '@/plugins/quasar-validators';


export function toSurveyQuestionModel(question: SurveyQuestionResponse, showHeader: boolean, response: SurveyDefinitionWrapperResponse): SurveyQuestionModel {
  const { questionId, questionTypeId, questionHeader, questionText, questionSubText, surveyAnswers, questionCategory } = question

  const convertHtml = function (str: string) {
    if (!str) {
      return ''
    }

    const temp = str.replace(/\n/g, '<br />')
      .replace(/\\n/g, '<br />')
      .replace(/&lt;color=black&gt;/g, '<span class="bold">')
      .replace(/&lt;\/color&gt;/g, '</span>')
      .replace(/&lt;b&gt;/g, '<span class="bold">')
      .replace(/&lt;\/b&gt;/g, '</span>')
      .replace(/&lt;u&gt;/g, '<span class="text-decoration">')
      .replace(/&lt;\/u&gt;/g, '</span>')
      .replace(/&lt;i&gt;/g, '<span class="italic">')
      .replace(/&lt;\/i&gt;/g, '</span>')
      .replace(/&lt;br \/&gt;/g, '<br />')
      .replace(/&lt;br&gt;/g, '<br>')
      .replace(/&lt;u&gt;&lt;b&gt;/g, '<u><b>')
      .replace(/&lt;\/b&gt;&lt;\/u&gt;/g, '</u></b>')

    const txt = document.createElement("textarea");
    txt.innerHTML = temp;
    return txt.value
  }

  /* eslint-disable @typescript-eslint/no-use-before-define */
  const renderingConfig = mapQuestionRenderingBehavior(question, showHeader, response.survey.surveyType)

  if (questionCategory === QuestionCategoryResponseEnum.NumericRangeFAAM) {
    const min = surveyAnswers[0],
      max = surveyAnswers[1]
    surveyAnswers.splice(0, surveyAnswers.length)
    surveyAnswers.push(<SurveyPermittedAnswerResponse>{
      ...min,
      range: {
        min: min.id,
        max: max.id
      }
    })
  } else if (question.questionCategory === QuestionCategoryResponseEnum.SliderDefinition) {
    const { start, end, scale } = question.questionConfig.slider.range

    surveyAnswers.push(<SurveyPermittedAnswerResponse>{
      range: {
        min: start,
        max: end,
        scale
      }
    })
  }

  const result: SurveyQuestionModel = {
    questionId,
    questionTypeId,
    questionHeader: convertHtml(questionHeader),
    questionText: convertHtml(questionText),
    questionSubText: convertHtml(questionSubText),
    renderingConfig,
    permittedAnswers: toSurveyPermittedAnswerModel(undefined, surveyAnswers, question, response)
  }
  return result
}

function mapQuestionRenderingBehavior(question: SurveyQuestionResponse, showHeader: boolean, surveyType: SurveyTypeEnum) {
  const { questionCategory, surveyAnswers } = question

  const choiceTypes = [
    QuestionCategoryResponseEnum.DataEntry,
    QuestionCategoryResponseEnum.MultipleChoiceChooseOne,
    QuestionCategoryResponseEnum.MultipleManyChoiceType,
    QuestionCategoryResponseEnum.MultipleChoice,
    QuestionCategoryResponseEnum.MultipleChoiceChooseMany,
    QuestionCategoryResponseEnum.MultipleChoiceChooseOneSingleNest,
    QuestionCategoryResponseEnum.NumericRangeFAAM,
  ]

  const sliderTypes = [
    QuestionCategoryResponseEnum.SliderIncrements,
    QuestionCategoryResponseEnum.SliderIncrementsVertical,
    QuestionCategoryResponseEnum.SliderIncrementsVerticalNoLabel,
    QuestionCategoryResponseEnum.SliderRangeNRS,
    QuestionCategoryResponseEnum.SliderRangeP10,
    QuestionCategoryResponseEnum.SliderRangeVAS,
    QuestionCategoryResponseEnum.SliderDefinition
  ]

  const buttonTypes = [
    QuestionCategoryResponseEnum.Declinable
  ]

  const allVerticalSliderTypes = [
    QuestionCategoryResponseEnum.SliderIncrementsVertical,
    QuestionCategoryResponseEnum.SliderIncrementsVerticalNoLabel,
    QuestionCategoryResponseEnum.SliderNoIncrementsVertical
  ]

  const slidersThatHideSliderValueDisplay = [
    QuestionCategoryResponseEnum.SliderRangeNRS,
    QuestionCategoryResponseEnum.SliderIncrements
  ]

  const slidersThatDisplayCheckmarkInLieuOfValueDisplay = [
    QuestionCategoryResponseEnum.SliderRangeVAS
  ]

  const slidersThatDoNotRenderTickMarks = [
    QuestionCategoryResponseEnum.SliderRangeVAS
  ]

  const painAndOpioidSurveys = [
    SurveyTypeEnum.Pain_and_Opioid_Pre_Op,
    SurveyTypeEnum.Pain_and_Opioid_Post_Op
  ]

  const canSkip = question.canSkip || questionCategory === QuestionCategoryResponseEnum.Instruction;

  let type = SurveyComponentQuestionTypeEnum.unknown
  if (choiceTypes.includes(questionCategory)) {
    type = SurveyComponentQuestionTypeEnum.choice
  } else if (sliderTypes.includes(questionCategory)) {
    type = SurveyComponentQuestionTypeEnum.slider
  } else if (buttonTypes.includes(questionCategory)) {
    type = SurveyComponentQuestionTypeEnum.button
  }

  let orientation = ''
  let minValue = 0
  let maxValue = 0
  let scale = 1
  let tickMarks: Record<number, string> = {} //keys define tick marks on slider, values define labels to render above slider at the tick mark
  let numberOfTickMarks: boolean | number = true;
  let tickLabels: string[] = [] //displayed under the slider for horizontal
  let sliderValueBehavior = SliderValueBehaviorEnum.show
  let verticalSliderUnitText = ''

  if (type === SurveyComponentQuestionTypeEnum.slider) {
    let minAnswer: SurveyPermittedAnswerResponse,
      maxAnswer: SurveyPermittedAnswerResponse


    if (question.questionCategory === QuestionCategoryResponseEnum.SliderDefinition) {
      const config = question.questionConfig?.slider
      if (config) {
        tickMarks = config.tickMarks.reduce((obj, key,) => {
          const label = config.tickLabels.find(lbl => lbl === key.toString())?.toString() || ''
          return {
            ...obj,
            [key.toString()]: label
          }
        }, {});
        tickLabels = config.descriptiveLabels
        orientation = config.orientation === 0 ? SliderOrientationEnum.horizontal : SliderOrientationEnum.vertical
        minValue = config.range.start
        maxValue = config.range.end
        scale = config.range.scale ?? scale

        if (config.titleLabel.onSlide === OnSlideEnum.Checkmark) {
          sliderValueBehavior = SliderValueBehaviorEnum.checkmark
        }
      }

      if (tickMarks && Object.keys(tickMarks).length == 0) {
        numberOfTickMarks = false;
      }

    } else {

      tickLabels = surveyAnswers.map(a => a.name)

      if (surveyAnswers.length === 2) {
        minAnswer = surveyAnswers[0]
        maxAnswer = surveyAnswers[1]

        //behavior: calculate the median answer
        //reason: Some sliders render a median unit label, but the api does not supply an answer for it
        const calcMidAnswer = Math.floor(maxAnswer.id / 2)
        tickMarks = {
          [minAnswer.id]: minAnswer.id.toString(),
          [calcMidAnswer]: calcMidAnswer.toString(),
          [maxAnswer.id]: maxAnswer.id.toString()
        }

        //behavior: slider components expect there to be 3 entries which are rendered as labels that are divided equally below slider to line up with min, mid, max unit labels above the slider
        //reason: Some sliders have 2 or 3 labels returned from the api. ensure it always has 3 entries
        tickLabels.splice(1, 0, '')
      } else {
        minAnswer = surveyAnswers[0]
        const midAnswer = surveyAnswers[1]
        maxAnswer = surveyAnswers[2]

        //behavior: default to having unit labels for min, mid, and max values
        //reason: every survey except P&O surveys behaves this way
        tickMarks = {
          [minAnswer.id]: minAnswer.id.toString(),
          [midAnswer.id]: midAnswer.id.toString(),
          [maxAnswer.id]: maxAnswer.id.toString()
        }

        //behavior: display nothing for the mid answer
        //reason: the api returns 3 answers in the form [words, number, words] and is a bug. Should not have a mid answer tick label
        if (questionCategory === QuestionCategoryResponseEnum.SliderRangeP10) {
          tickLabels[1] = ''
        }
      }

      minValue = minAnswer.id
      maxValue = maxAnswer.id

      if (allVerticalSliderTypes.includes(questionCategory)) {
        orientation = SliderOrientationEnum.vertical
      } else {
        orientation = SliderOrientationEnum.horizontal
      }

      //behavior: detects what will be displayed above the slider when a value is selected
      //reason: there are 3 flavors to support { display the number selected, a checkmark, or nothing }
      if (slidersThatHideSliderValueDisplay.includes(questionCategory)) {
        sliderValueBehavior = SliderValueBehaviorEnum.hide
      } else if (slidersThatDisplayCheckmarkInLieuOfValueDisplay.includes(questionCategory)) {
        sliderValueBehavior = SliderValueBehaviorEnum.checkmark
      }

      //behavior: displays a text string underneath the whatever is rendered by the config 'sliderValueBehavior'
      if (questionCategory !== QuestionCategoryResponseEnum.SliderIncrementsVerticalNoLabel) {
        //behavior: load specific kvp into text dislayed below selected value
        //reason: a kvp is used, so it is likely survey specific
        if (surveyType === SurveyTypeEnum.EQ_5D_5L_Health) {
          verticalSliderUnitText = i18n.global.t('survey_your_health_today').toString()
        }
      }

      //behavior: vertical sliders should have only certain number labels and certain increments of tick marks rendered
      //reason: the api response doesnt structure the answers in a fashion where there is an answer for each tick mark so it must be calculated
      if (surveyType === SurveyTypeEnum.EQ_5D_5L_Health) {
        tickMarks = {
          0: '0',
          50: '50',
          100: '100'
        }
        numberOfTickMarks = 10
      } else if (surveyType === SurveyTypeEnum.KPS) {
        tickMarks = {
          [minValue]: minValue.toString(),
          [maxValue]: maxValue.toString()
        }
      } else if (surveyType === SurveyTypeEnum.VAS
        || surveyType === SurveyTypeEnum.JKOM
        || surveyType === SurveyTypeEnum.JHEQ) {
        tickMarks = {}
        numberOfTickMarks = false
      }

      //behavior: For P&O surveys, configure the value display behavior to show the selected value and set the unit labels to the min and max
      //reason: P&O surveys have these custom display characterstics compared to all other horizontal surveys
      if (painAndOpioidSurveys.includes(surveyType)) {
        sliderValueBehavior = SliderValueBehaviorEnum.show
        tickMarks = {
          [minValue]: minValue.toString(),
          [maxValue]: maxValue.toString()
        }
      }
    }
  }

  return {
    type,
    orientation,
    minValue,
    maxValue,
    scale,
    tickMarks, //controls what the labels of a tick mark on the q-slider will be
    numberOfTickMarks, //controls how many tick marks appears on q-slider
    tickLabels, //custom tick labels generated by SurveySlider.vue
    verticalSliderUnitText,
    sliderValueBehavior,
    order: question.sequence,
    canSkip,
    showHeader,
    boldQuestionText: false,
  }
}