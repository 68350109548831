<script lang="ts" setup>
import { dateInput } from 'src/plugins/quasar-validators';;import {storeToRefs} from 'pinia';
import {useLocalizationStore} from '@/store/localizationModule';
import {
  computed,
  defineEmits,
  defineModel,
  defineProps,
  type PropType,
  ref,
} from 'vue';
import {FormElWidthEnum} from 'src/constants/enums';
import type {ValidationRule} from 'quasar';
import moment from 'momentCultured';

const { locale } = storeToRefs(useLocalizationStore())

const emit = defineEmits(['update:model-value'])

const props = defineProps({
  dark: {
    type: Boolean,
    default: false
  },
  disable: {
    type: Boolean,
    default: false,
  },
  for: {
    type: String
  },
  /*
   * Controls the width value of the form element. Options are:
   * qFormElNone - does not apply a class, therefore no width rule.
   * qFormElAuto - sets width to `auto`.
   * qFormEl100 - sets width to `100px`.
   * qFormEl150 - sets width to `150px`.
   * qFormEl200 - sets width to `200px`.
   * qFormEl250 - sets width to `250px`.
   * qFormEl310 - sets width to `310px`.
   */
  inputWidth: {
    type: String as PropType<FormElWidthEnum>,
    default: FormElWidthEnum.qFormEl300,
  },
  label: {
    type: String
  },
  maxDate: {
    type: Date,
    default: undefined,
  },
  minDate: {
    type: Date,
    default: undefined,
  },
  outlined: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
  },
  readonly: {
    type: Boolean,
    default: false
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  }
})

const datepicker = ref();
const syncSelectedDate = defineModel();
const syncRules = computed({
  get() {
    if (props.rules && props.rules.length)
      return [...props.rules, dateInput()]
    else
      return [dateInput()]
  }
})

const regionalMask = computed(() => {
  return moment.localeData()._longDateFormat['L'].toLowerCase()
    .replace(/m/gi, '0')
    .replace(/d/gi, '0')
    .replace(/y/gi, '0');
});

const eventWrapper = (handler, event) => {
  handler(event)
}
</script>

<template>

  <!-- Label -->
  <label v-if="props.label"
         :for="props.for">
    {{ props.label }}<slot name="label-append" />
  </label>

  <!-- Field -->
  <q-field v-model="syncSelectedDate"
           :class="[props.inputWidth]"
           :dark="props.dark"
           :disable="props.disable"
           hide-bottom-space
           no-error-icon
           :outlined="props.outlined"
           :readonly="props.readonly"
           :rules="syncRules"
           tab="div">

    <!-- Control Slot -->
    <template #control>
      <VDatePicker v-model="syncSelectedDate"
                   :attributes="[{
                     key: 'today',
                     bar: {
                       color: 'blue',
                       fillMode: 'solid'
                       },
                       dates: new Date()
                   }]"
                   clearable
                   color="teal"
                   :locale="locale"
                   :max-date="maxDate"
                   :min-date="minDate"
                   mode="date"
                   :popover="{
                     placement: 'bottom',
                     visibility: 'click'
                   }"
                   ref="datepicker"
                   :updateOnInput="true">
        <template #default="{ inputValue, inputEvents }">
          <input @change.native="(e) => eventWrapper(inputEvents.change, e)"
                 @click.native="(e) => eventWrapper(inputEvents.click, e)"
                 @input.native="(e) => eventWrapper(inputEvents.input, e)"
                 @keyup.native="(e) => eventWrapper(inputEvents.keyup, e)"
                 autocomplete="off"
                 :name="props.for"
                 :placeholder="props.placeholder ? props.placeholder : regionalMask"
                 type="text"
                 :value="inputValue" />
        </template>
      </VDatePicker>
    </template>
  </q-field>
</template>

<style lang="scss" scoped>

  // QField
  .q-field {

    // Native
    :deep(.q-field__native) {
      background-color: initial;
      padding-block: 0;
      border-block-end: none;
    }

    // Date Picker Input
    input {
      width: 100%;
      background-color: $white;
      color: $dark-teal;
      line-height: $body-line-height;
      padding-block: $field-padding-block $field-padding-block-outlined;
      padding-inline: 0;
      border: none;
      border-block-end: 1px solid $body-txt-clr;
      border-radius: 0;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $input-placeholder-clr;
        opacity: 1;
      }
    }

    // Outlined
    &--outlined { // .q-field

      // Native
      :deep(.q-field__native) {
        padding-block-start: 0;
        padding-inline: 0;
        border: none;
        border-radius: 0;
      }

      // Date Picker Input
      input {
        padding-block: $field-padding-block-outlined;
        padding-inline: $field-padding-inline;
        border: $generic-border;
        border-radius: $generic-border-radius-sm;
      }
    }

    // Auto Height
    &--auto-height { // .q-field

      // Labeled & Outlined
      &.q-field--labeled,
      &.q-field--outlined { // .q-field

        // Native
        :deep(.q-field__native) {
          padding-block-start: 0;
        }
      }
    }

    // Error
    &--error { // .q-field

      // Date Picker Input
      input {
        background-color: $notify-yellow;
      }
    }

    // Dark
    &--dark { // .q-field
      
      // Date Picker Input
      input {
        background-color: transparent;
        color: $white;
        border-color: $white;

        &::placeholder {
          color: $white !important;
        }
      }
    }
  }

  :deep(button) {
    background-color: transparent;
  }

  :deep(.vc-container) {
    width: auto;
    height: auto;
  }
</style>