<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="onBackClick">

      <!-- Title -->
      <template v-slot:title>{{ $t('settings') }}</template>

      <!-- Heading Text/Content -->
      <template v-slot:heading>{{ $t('settings_legal_and_privacy') }}</template>

      <!-- View Unique Content -->
      <template v-slot:default>

        <!-- Legal Doc Navigation -->
        <q-list>
          <NavigationItem :to="{ name: 'Settings.SafetyInformation' }" :label="$t('routine_safety')" />
          <NavigationItem :to="{ name: 'Settings.PrivacyPolicy' }" :label="$t('activate_privacy_policy')" />
          <NavigationItem :to="{ name: 'Settings.EULA' }" :label="$t('settings_eula')" />
        </q-list>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import NavigationItem from 'src/components/NavigationItem.vue';

const router = useRouter();

function onBackClick() {
  router.push({ name: 'Settings' });
}
</script>