<template>
  <div class="sub-navigation-layout">

    <!-- Heading -->
    <div class="row align-items-center sub-navigation-layout__heading">

      <!-- Title -->
      <div class="col">
        <h1>{{ $t(title) }}</h1>
      </div>

      <!-- More Information -->
      <slot name="more-info" />
    </div>

    <!-- Navigation Options -->
    <div class="row sub-navigation-layout__nav">

      <slot name="navigation">
        <div v-if="type === 'tab'" class="col">
          <div class="row no-gutters tab-nav">
            <!-- Navigation Items -->
            <q-tabs v-model="selectedTab" no-caps dense class="bg-transparent text-dark-teal">
              <q-route-tab v-for="(item, i) in navItems" :key="i" :to="{ name: item.path }" class="text-weight-bold"
                exact>{{ item.label
                }}</q-route-tab>
            </q-tabs>
          </div>
        </div>
      </slot>
    </div>

    <!-- Navigation Content -->
    <div class="row">
      <div class="col">
        <slot name="content">
          <router-view :key="$route.fullPath" v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType, defineProps, defineEmits, computed, ref, onActivated, watch  } from 'vue';
import { useRoute } from 'vue-router';

const emit = defineEmits(['update:modelValue']);

const route = useRoute();
const selectedTab = ref()

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  /*
  * Controls the navigation type. Options are:
  * tab (default)
  * dropdown
  */
  type: {
    type: String,
    default: 'tab',
  },
  /*
  * Array/Collection of objects we use to create the 2 different sub-navigation options.
  * order: number value used to sort the list in a specific order. Sorting needs to be done outside this component.
  * localizedName: the localized text value, this needs to already be turned into its value, do not provide the key.
  * path: provide the Named Route object value, not a URL.
  * disabled: only used if the `type` Prop equals dropdown. Disables the link or button.
  */
  navItems: {
    type: Array as PropType<Array<DropdownItem>>,
    required: false,
  },
  modelValue: {
    type: Object as PropType<DropdownItem>,
    default: () => ({
      value: undefined,
      label: '',
      path: undefined,
      disabled: false,
      params: {},
    }),
  },
});

const selectedDdItem = ref(props.modelValue)

const model = computed({
  get(): any {
    return selectedDdItem.value;
  },
  set(value) {
    selectedDdItem.value = value
    emit('update:modelValue', value);
  },
});

const updateSelectedTab = (newRoute, oldRoute) => {
  if (!newRoute || !selectedTab)
    return;

  if (newRoute.includes('PreSurgery')) {
    selectedTab.value = 't_0';
  } else if (newRoute.includes('PostSurgery')) {
    selectedTab.value = 't_1';
  } else if (newRoute.includes('Technology')) {
    selectedTab.value = 't_2';
  }
};

watch(
  () => route.name,
  (newRouteName, oldRouteName) => {
    updateSelectedTab(newRouteName, oldRouteName);
  },
  { immediate: true }
);

</script>