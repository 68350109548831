<template>
  <SkeletonLoader :loading="isStatsLoading" width="600px" height="200px" type="text" :repeatCount="6"
    skeletonStyle="margin-bottom: 1.5em">
    <TheExpandableMyStat v-model:visible="stepsVisible" stat="steps" title="stats_steps"
      :headingTotal="stats.piqSteps.headingTotal" :headingDate="stats.piqSteps.headingDate" :piq="piq.toString()"
      :lastMonth="stats.piqSteps.lastMonth" :lastWeek="stats.piqSteps.lastWeek"
      :measurementType="MeasurementTypeEnum.PiqMaxSteps" :sourceType="StatTypeEnum.PersonaIQ" :device-id="deviceId"
      :ready-signal="readySignal">
    </TheExpandableMyStat>

    <TheExpandableMyStat v-model:visible="distanceVisible" stat="distance" title="stats_distance_piq"
      :measurementUnit="distanceLongName" :headingIcon="piqDistance"
      :headingTotal="stats.piqDistance.headingTotal" :headingDate="stats.piqDistance.headingDate" :piq="piq.toString()"
      :lastMonth="stats.piqDistance.lastMonth" :lastWeek="stats.piqDistance.lastWeek"
      :measurementType="MeasurementTypeEnum.PiqAvgDistance" :sourceType="StatTypeEnum.PersonaIQ" :device-id="deviceId"
      :ready-signal="readySignal">
    </TheExpandableMyStat>

    <TheExpandableMyStat v-model:visible="romVisible" stat="rom" title="stats_rom_piq" :measurementUnit="degreesName"
      :headingIcon="piqKneeRom" :headingTotal="stats.piqRom.headingTotal + deg"
      :headingDate="stats.piqRom.headingDate" :piq="piq.toString()" :lastMonth="stats.piqRom.lastMonth + deg"
      :lastWeek="stats.piqRom.lastWeek + deg" :measurementType="MeasurementTypeEnum.PiqAvgKneeROM"
      :sourceType="StatTypeEnum.PersonaIQ" :device-id="deviceId" :ready-signal="readySignal">
    </TheExpandableMyStat>

    <TheExpandableMyStat v-model:visible="speedVisible" stat="speed" title="stats_walking_speed_piq"
      :measurementUnit="distanceShortPerSecondName" :headingTotal="stats.piqSpeed.headingTotal"
      :headingDate="stats.piqSpeed.headingDate" :piq="piq.toString()" :lastMonth="stats.piqSpeed.lastMonth"
      :lastWeek="stats.piqSpeed.lastWeek" :measurementType="MeasurementTypeEnum.PiqAvgWalkSpeed"
      :sourceType="StatTypeEnum.PersonaIQ" :device-id="deviceId" :ready-signal="readySignal">
    </TheExpandableMyStat>

    <TheExpandableMyStat v-model:visible="strideVisible" stat="stride" title="stats_stride_length_piq"
      :measurementUnit="distanceShortName" :headingIcon="piqStrideLength"
      :headingTotal="stats.piqStride.headingTotal" :headingDate="stats.piqStride.headingDate" :piq="piq.toString()"
      :lastMonth="stats.piqStride.lastMonth" :lastWeek="stats.piqStride.lastWeek"
      :measurementType="MeasurementTypeEnum.PiqAvgStrideLength" :sourceType="StatTypeEnum.PersonaIQ"
      :device-id="deviceId" :ready-signal="readySignal">
    </TheExpandableMyStat>

    <TheExpandableMyStat v-model:visible="cadenceVisible" stat="cadence" title="stats_cadence_piq"
      :measurementUnit="stepsPerMinuteName" :headingIcon="piqCadence"
      :headingTotal="stats.piqCadence.headingTotal" :headingDate="stats.piqCadence.headingDate" :piq="piq.toString()"
      :lastMonth="stats.piqCadence.lastMonth" :lastWeek="stats.piqCadence.lastWeek"
      :measurementType="MeasurementTypeEnum.PiqCadence" :sourceType="StatTypeEnum.PersonaIQ" :device-id="deviceId"
      :ready-signal="readySignal">
    </TheExpandableMyStat>
  </SkeletonLoader>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { toPiqStatSummary } from 'src/services/statisticsService';
import { DEGREE_SYMBOL } from 'src/constants/units';
import TheExpandableMyStat from 'src/pages/DashboardMyStats/components/TheExpandableMyStat.vue';
import SkeletonLoader from 'src/components/SkeletonLoader.vue';
import { useDashboardStore } from 'src/store';
import { MeasurementTypeEnum, StatTypeEnum } from 'src/constants/measurementTypes';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getPatientStatisticSummaries } from 'src/api/endpoints/statisticsWeb';
import { useQuasar } from 'quasar';
import {
  piqCadence,
  piqDistance,
  piqKneeRom,
  piqStrideLength
} from '@/assets';

const $q = useQuasar()
const dashboardStore = useDashboardStore()

const props = defineProps({
  deviceId: { type: Number, required: false },
  readySignal: Number
})

const stepsVisible = ref(false)
const distanceVisible = ref(false)
const romVisible = ref(false)
const speedVisible = ref(false)
const strideVisible = ref(false)
const cadenceVisible = ref(false)

const hydrate = async () => {
  stepsVisible.value = false
  distanceVisible.value = false
  romVisible.value = false
  speedVisible.value = false
  strideVisible.value = false
  cadenceVisible.value = false

  if (stats.value.loaded) return;

  const response = await handleApiRequest(() => getPatientStatisticSummaries(StatTypeEnum.PersonaIQ, props.deviceId), null, $q);

  if (response?.result) {
    dashboardStore.setStatisticSummaries({ patientStats: response.result, sourceKey: sourceKey.value })
  }
}

watch([() => props.readySignal],
  ([newReadySignal]) => {
    hydrate()
  })

const piq = ref(true);
const deg = ref(DEGREE_SYMBOL);
const sourceKey = computed(() => `${StatTypeEnum.PersonaIQ}-${props.deviceId}`);
const distanceLongName = computed(() => 'unit_plural_name_distance_miles');
const distanceShortName = computed(() => 'unit_plural_name_distance_short_imperial');
const distanceShortPerSecondName = computed(() => 'unit_plural_name_distance_short_per_second_imperial');
const stepsPerMinuteName = computed(() => 'unit_plural_steps_per_minute');
const degreesName = computed(() => 'unit_plural_name_degrees');
const isStatsLoading = computed(() => !dashboardStore.statisticSummaries[sourceKey.value]);
const stats = computed(() => {
  const result = toPiqStatSummary(
    dashboardStore.statisticSummaries[sourceKey.value] || {
      patientId: 0,
      details: [],
    }
  );
  return result;
});


</script>