<script lang="ts" setup>
import {defineProps, type PropType} from 'vue';
import Svg from '@/components/Svg.vue';
import {mymobilityFor} from '@/assets';

const props = defineProps({
  authenticated: {
    type: Boolean as PropType<boolean | undefined>,
    required: true
  },
  logo: {
    type: String
  }
});
</script>

<template>
  <component :class="['flex-center co-branded-wordmark', props.authenticated ? 'auth' : 'not-auth']"
             :is="props.authenticated ? 'router-link' : 'div'"
             :to="props.authenticated ? { name: 'Dashboard.Home' } : null">
    
    <!-- mymobility for Wordmark -->
    <Svg :vector-paths="mymobilityFor.vectorPaths"
         :view-box="mymobilityFor.viewBox" />
    <div class="flex-br" />
    
    <!-- Co-Branded Logo -->
    <img :src="props.logo" />
  </component>
</template>

<style lang="scss" scoped>
.co-branded-wordmark { // <a> or <div>
  display: flex;
  line-height: 0;
  color: map-get($co-brand-clrs, content-grd-bg-content);
  
  &:focus,
  &:hover {
    
    &:not(.q-btn):not(.q-item) {
      text-decoration: none;
    }
  }

  // mymobility for Wordmark
  svg {
    width: 100%;
    max-width: 150px;
    height: 100%;
    max-height: 30px;
    margin-inline-end: 6px;
  }

  .flex-br {
    display: none;
  }
  
  // Co-Branding Logo
  img {
    width: auto;
    max-width: 180px;
    height: 100%;
    max-height: 40px;
  }
  
  // Authenticated
  &.auth { // <a> or <div>
    flex-direction: row;
    flex-wrap: nowrap;
  }
  
  // Not Authenticated
  &.not-auth { // <a> or <div>
    flex-wrap: nowrap;

    // mymobility for Wordmark
    svg {
      margin-inline-end: 6px;
    }
  }

  @media (max-width: 499px) {

    // Authenticated
    &.auth { // <a> or <div>
      flex-direction: column;
      flex-wrap: wrap;
      
      // mymobility for Wordmark
      svg {
        max-height: 26px;
        margin-inline-end: 0;
      }

      .flex-br {
        display: block;
      }
    }
  }
}
</style>