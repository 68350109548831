import axios from 'src/api/axiosConfig'
import { ApiResponse } from 'src/types/apiTypes';
import { PatientDailyStatisticType, PatientDataSourcesResponse, PatientStatisticsResponse } from 'src/types/webContracts'
import { AxiosResponse } from 'axios';
import { ApiRoutes } from '../ApiRoutes'

export async function getPatientStatisticSummaries(source?: string, device?: number): Promise<AxiosResponse<ApiResponse<PatientStatisticsResponse>>> {
  const response = await axios.get(ApiRoutes.Statistics.PatientStatisticSummaries,  { params: { source, device } });
  return response;
}

export async function getPatientMeasurementStats(startDate: string, endDate: string, measurementType: string, source: string, device: number):
  Promise<AxiosResponse<ApiResponse<Record<string, PatientDailyStatisticType>>>> {
  const response = await axios.get(ApiRoutes.Statistics.PatientMeasurementStats, { params: { startDate, endDate, measurementDataTypeNames: measurementType, source, device } });
  return response;
}

export async function getPatientDataSources(): Promise<AxiosResponse<ApiResponse<PatientDataSourcesResponse>>> {
  const response = await axios.get(ApiRoutes.Statistics.PatientDataSources);
  return response;
}