import { type RouteRecordRaw } from 'vue-router';
import * as Routes from 'src/router/spaRoutes';
import { AuthPolicyEnum } from 'src/constants/authPolicyEnum';

//Components
import TheMobileAppPromo from 'src/components/TheMobileAppPromo.vue';
import LibraryVideoItem from 'src/pages/Education/components/LibraryEducationVideo.vue';
import LibraryHtmlItem from 'src/pages/Education/components/LibraryEducationHtml.vue';

// Pages
import TheWelcomePage from 'src/pages/Login/TheWelcomePage.vue';
import SystemMaintenance from 'src/pages/SystemMaintenance/TheSysMaint.vue';

import Authenticate from 'src/pages/Authenticate.vue';
import ConfirmIdentity from 'src/pages/ConfirmIdentity.vue';

import TheActivationComplete from 'src/pages/Activation/TheActivationComplete.vue';
import TheActivateCreatePassword from 'src/pages/Activation/TheActivateCreatePassword.vue';
import TheActivateCreateUsername from 'src/pages/Activation/TheActivateCreateUsername.vue';
import TheActivateEULA from 'src/pages/Activation/TheActivateEULA.vue';
import TheActivateCrossBorderDataTransfer from 'src/pages/Activation/TheActivateCrossBorderDataTransfer.vue';
import TheActivateGlobalConsent from 'src/pages/Activation/TheActivateGlobalConsent.vue';
import TheActivateOverview from 'src/pages/Activation/TheActivateOverview.vue';
import TheActivatePrivacyPolicy from 'src/pages/Activation/TheActivatePrivacyPolicy.vue';
import TheActivateProfileInformation from 'src/pages/Activation/TheActivateProfileInformation.vue';
import TheActivateProfilePhoto from 'src/pages/Activation/TheActivateProfilePhoto.vue';
import TheActivateStart from 'src/pages/Activation/TheActivateStart.vue';

import TheLoginForm from 'src/pages/Login/TheLoginForm.vue';
import TheForgotUsername from 'src/pages/Login/TheForgotUsername.vue';
import TheForgotPassword from 'src/pages/Login/TheForgotPassword.vue';
import TheSetPassword from 'src/pages/Login/TheSetPassword.vue';

import TheSelectCarePlan from 'src/pages/TheSelectCarePlan.vue';
import TheInterrupts from 'src/pages/TheInterrupts.vue';
import TheAboutYourData from 'src/pages/TheAboutYourData.vue';
import TheDashboard from 'src/pages/TheDashboard.vue';

import TheDashboardHome from 'src/pages/DashboardHome/TheDashboardHome.vue';

import TheDashboardMyStats from 'src/pages/DashboardMyStats/TheDashboardMyStats.vue';

import TheDashboardEducation from 'src/pages/Education/TheDashboardEducation.vue';
import ThePreSurgeryEducationLibrary from 'src/pages/Education/ThePreSurgeryEducationLibrary.vue';
import ThePostSurgeryEducationLibrary from 'src/pages/Education/ThePostSurgeryEducationLibrary.vue';
import TheTechnologyEducationLibrary from 'src/pages/Education/TheTechnologyEducationLibrary.vue';
import TheROSAKneeEducationLibrary from 'src/pages/Education/TheROSAKneeEducationLibrary.vue';

import TheDashboardMessages from 'src/pages/DashboardMessages/TheDashboardMessages.vue';
import TheYourCareTeam from 'src/pages/DashboardMessages/components/TheYourCareTeam.vue';

import Education from 'src/pages/Education/Education.vue';

import TheSurveyContainer from 'src/pages/Survey/TheSurveyContainer.vue';

import Routine from 'src/pages/Routine/Routine.vue';
import Exercise from 'src/pages/Routine/Exercise.vue';
import RateRoutine from 'src/pages/Routine/RateRoutine.vue';
import CompletedRoutine from 'src/pages/Routine/CompletedRoutine.vue';

import ThePainRating from 'src/pages/DashboardHome/ThePainRating.vue';
import TheDailyWalkingGoal from 'src/pages/DashboardHome/TheDailyWalkingGoal.vue';

//Settings
import TheSettingsMenu from 'src/pages/TheSettingsMenu.vue';
import TheSupport from 'src/pages/TheSettings/TheSupport.vue';
import TheAbout from 'src/pages/TheSettings/TheAbout.vue';
import TheLegalAndPrivacy from 'src/pages/TheSettings/TheLegalAndPrivacy.vue';
import TheSettingsPrivacyPolicy from 'src/pages/TheSettings/TheSettingsPrivacyPolicy.vue';
import TheSettingsSafetyInformation from 'src/pages/TheSettings/TheSettingsSafetyInformation.vue';
import TheSettingsEULA from 'src/pages/TheSettings/TheSettingsEULA.vue';
import TheChangePassword from 'src/pages/TheSettings/TheChangePassword.vue';

import TheBaseStationSetup from 'src/pages/PIQSetup/TheBaseStationSetup.vue';

//PIQ Tasks
import TheStartPage from 'src/pages/PIQSetup/TheStartPage.vue'
import TheCompletePage from 'src/pages/PIQSetup/TheCompletePage.vue'
import TheCanaryTermsandConditions from 'src/pages/PIQSetup/TheCanaryTermsandConditions.vue';
import TheCanaryPrivacyPolicy from 'src/pages/PIQSetup/TheCanaryPrivacyPolicy.vue';
import TheAddressForm from 'src/pages/PIQSetup/TheAddressForm.vue';
import TheHeightWeightForm from 'src/pages/PIQSetup/TheHeightWeightForm.vue';
import TheCaregiverForm from 'src/pages/PIQSetup/TheCaregiverForm.vue';
import TheTimezoneSelection from 'src/pages/PIQSetup/TheTimezoneSelection.vue';
import TheBaseStationPrecheck from 'src/pages/PIQSetup/TheBaseStationPrecheck.vue';
import { PIQSetupPages } from 'src/services/piqSetupService';
import { StatTypeEnum } from '@/constants/measurementTypes';
import { i18n } from '@/plugins/i18n';
import { useDashboardStore, useSessionStore } from '@/store';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: 'Login' }
  },
  {
    path: '/SystemMaintenance',
    name: 'SystemMaintenance',
    component: SystemMaintenance,
    meta: { allowAnonymous: true },
  },
  {
    path: '/Authenticate/:returnPath?',
    name: 'Authenticate',
    component: Authenticate,
    meta: { allowAnonymous: true },
    props: route => ({ ...route.query, ...route.params })
  },
  {
    path: '/Welcome',
    name: 'Welcome',
    component: TheWelcomePage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: Routes.loginForm,
    name: 'Login',
    component: TheLoginForm,
    meta: {
      allowAnonymous: true,
      title: []
    }
  },
  {
    path: '/PIQSetup/Start',
    name: `PIQSetup.${PIQSetupPages.Start}`,
    component: TheStartPage,
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/PIQSetup/Complete',
    name: `PIQSetup.${PIQSetupPages.Complete}`,
    props: true,
    component: TheCompletePage
  },
  {
    path: '/PIQSetup/HeightWeight',
    name: `PIQSetup.${PIQSetupPages.HeightWeight}`,
    component: TheHeightWeightForm,
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/PIQSetup/Address',
    name: `PIQSetup.${PIQSetupPages.Address}`,
    component: TheAddressForm,
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/PIQSetup/Caregiver',
    name: `PIQSetup.${PIQSetupPages.Caregiver}`,
    component: TheCaregiverForm,
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/PIQSetup/CanaryTerms',
    name: `PIQSetup.${PIQSetupPages.Terms}`,
    component: TheCanaryTermsandConditions,
    meta: {
      policy: AuthPolicyEnum.FullTrustPatient
    }
  },
  {
    path: '/PIQSetup/CanaryPrivacy',
    name: `PIQSetup.${PIQSetupPages.PrivacyPolicy}`,
    component: TheCanaryPrivacyPolicy,
    meta: {
      policy: AuthPolicyEnum.FullTrustPatient
    }
  },
  {
    path: '/PIQSetup/BedTime',
    name: `PIQSetup.${PIQSetupPages.Bedtime}`,
    props: true,
    component: TheTimezoneSelection
  },
  {
    path: '/PIQSetup/BaseStationSetup',
    name: 'PIQSetup.BaseStationSetup',
    props: true,
    component: TheBaseStationSetup
  },
  {
    path: '/PIQSetup/BaseStationPrecheck',
    name: 'PIQSetup.BaseStationPrecheck',
    props: true,
    component: TheBaseStationPrecheck
  },
  {
    path: '/ConfirmIdentity',
    name: 'ConfirmIdentity',
    component: ConfirmIdentity,
    meta: {
      allowAnonymous: true,
      title: ['activate_confirm_identity_title']
    },
    props: (route: any) => {

      const params = route.query;
      params.workflow = parseInt(params.workflow, 10);
      params.day = parseInt(params.day, 10);
      params.month = parseInt(params.month, 10);
      params.year = parseInt(params.year, 10);
      params.progress = parseInt(params.progress, 10);

      return params;
    }
  },
  {
    path: '/Activate',
    name: 'Activate',
    component: TheActivateOverview,
    meta: {
      allowAnonymous: true,
      title: ['activation', 'overview']
    }
  },
  {
    path: '/Activate/Start',
    name: 'Activate.Start',
    component: TheActivateStart,
    meta: {
      allowAnonymous: true,
      title: ['activation', 'start']
    }
  },
  {
    path: '/Activate/PrivacyPolicy',
    name: 'Activate.PrivacyPolicy',
    component: TheActivatePrivacyPolicy,
    meta: {
      policy: AuthPolicyEnum.UnregisteredPatientWith2FA,
      title: ['activation', 'activate_privacy_policy']
    }
  },
  {
    path: '/Activate/EULA',
    name: 'Activate.EULA',
    component: TheActivateEULA,
    meta: {
      // policy: AuthPolicyEnum.UnregisteredPatientWith2FA,
      title: ['activation', 'activate_eula']
    }
  },
  {
    path: '/Activate/CrossBorderDataTransfer',
    name: 'Activate.CrossBorderDataTransfer',
    component: TheActivateCrossBorderDataTransfer,
    meta: {
      policy: AuthPolicyEnum.UnregisteredPatientWith2FA,
      title: ['activation', 'china_activate_cross_border']
    }
  },
  {
    path: '/Activate/GlobalConsent',
    name: 'Activate.GlobalConsent',
    component: TheActivateGlobalConsent,
    meta: {
      policy: AuthPolicyEnum.UnregisteredPatientWith2FA,
      title: ['activation', 'consent_global_consent']
    }
  },
  {
    path: '/Activate/CreateUsername',
    name: 'Activate.CreateUsername',
    component: TheActivateCreateUsername,
    meta: {
      policy: AuthPolicyEnum.UnregisteredPatientWith2FA,
      title: ['activate_username_create_new']
    }
  },
  {
    path: '/Activate/CreatePassword',
    name: 'Activate.CreatePassword',
    component: TheActivateCreatePassword,
    props: true,
    meta: {
      policy: AuthPolicyEnum.UnregisteredPatientWith2FA,
      title: ['password_create_password_capitalized']
    }
  },
  {
    path: '/Activate/ProfileInformation',
    name: 'Activate.ProfileInformation',
    component: TheActivateProfileInformation,
    meta: {
      policy: AuthPolicyEnum.UnregisteredPatientWith2FA,
      title: ['activate_account']
    }
  },
  {
    path: '/Activate/ProfilePhoto',
    name: 'Activate.ProfilePhoto',
    component: TheActivateProfilePhoto,
    meta: {
      policy: AuthPolicyEnum.UnregisteredPatientWith2FA,
      title: ['activate_account']
    }
  },
  {
    path: '/Activate/Complete',
    name: 'Activate.Complete',
    component: TheActivationComplete,
    meta: {
      policy: AuthPolicyEnum.UnregisteredPatientWith2FA,
      title: ['activate_account']
    }
  },
  {
    path: '/ForgotUsername',
    name: 'ForgotUsername',
    component: TheForgotUsername,
    props: true,
    meta: {
      allowAnonymous: true,
      title: ['forgot_username']
    }
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: TheForgotPassword,
    props: true,
    meta: {
      allowAnonymous: true,
      title: ['forgot_password_title']
    }
  },
  {
    path: '/SetPassword',
    name: 'SetPassword',
    component: TheSetPassword,
    props: true,
    meta: {
      policy: AuthPolicyEnum.InForgottonPasswordWorkflow,
      title: ['password_create_password_capitalized']
    }
  },
  {
    path: '/SelectCarePlan',
    name: 'SelectCarePlan',
    component: TheSelectCarePlan,
    props: true,
    meta: {
      policy: AuthPolicyEnum.FullTrustPatient,
      title: []
    }
  },
  {
    path: '/Interrupts:reload?',
    name: 'Interrupts',
    component: TheInterrupts,
    props: true,
    meta: {
      policy: AuthPolicyEnum.FullTrustPatient,
      title: []
    },
  },
  {
    path: '/AboutYourData',
    name: 'AboutYourData',
    component: TheAboutYourData,
    props: true,
    meta: {
      policy: AuthPolicyEnum.FullTrustPatient,
      title: ['about_your_data_header']
    }
  },
  {
    path: '/Dashboard',
    redirect: { name: 'Dashboard.Home' },
    name: 'Dashboard',
    component: TheDashboard,
    props: true,
    meta: {
      policy: AuthPolicyEnum.FullTrustPatient
    },
    beforeEnter: (to, from, next) => {
      const sessionStore = useSessionStore();
      const dashboardStore = useDashboardStore();
      if (!sessionStore?.user?.selectedEocId) {
        next({ name: 'SelectCarePlan' });
      }
      else if (to.matched.some((item: any) => item.name === 'Settings')) {
        next();
      } else if (dashboardStore.patient?.painAndOpioidData?.checkinRequired) {
        next({ name: 'PainRating' });
      }
      else {
        next();
      }
    },
    children: [
      {
        path: 'Home/:reload?',
        name: 'Dashboard.Home',
        components: {
          auxiliary: TheMobileAppPromo,
          default: TheDashboardHome
        },
        meta: {
          title: ['home_home_android']
        },
        props: {
          default: true
        }
      },
      {
        path: 'MyStats/:eocDeviceId?',
        name: 'Dashboard.MyStats',
        components: {
          auxiliary: TheMobileAppPromo,
          default: TheDashboardMyStats
        },
        meta: {
          title: ['stats']
        }
      },
      {
        path: 'MyStats/PersonaIQ/:eocDeviceId?',
        name: 'Dashboard.MyStats.PersonaIQ',
        components: {
          auxiliary: TheMobileAppPromo,
          default: TheDashboardMyStats
        },
        meta: {
          title: ['stats_header_piq', 'stats']
        },
        props: route => ({
          sourceType: StatTypeEnum.PersonaIQ.toString()
        })
      },
      {
        path: 'Education',
        redirect: { name: 'Dashboard.Education.PreSurgery' },
        name: 'Dashboard.Education',
        components: {
          auxiliary: TheMobileAppPromo,
          default: TheDashboardEducation
        },
        meta: {
          title: ['gen_education']
        },
        props: true,
        children: [
          {
            path: 'PreSurgery',
            name: 'Dashboard.Education.PreSurgery',
            components: {
              default: ThePreSurgeryEducationLibrary
            },
            meta: {
              title: ['education_pre_surgery', 'gen_education']
            }
          },
          {
            path: 'PostSurgery',
            name: 'Dashboard.Education.PostSurgery',
            components: {
              default: ThePostSurgeryEducationLibrary
            },
            meta: {
              title: ['education_post_surgery', 'gen_education']
            }
          },
          {
            path: 'Technology',
            name: 'Dashboard.Education.Technology',
            components: {
              default: TheTechnologyEducationLibrary
            },
            meta: {
              title: ['pwe_technology_library', 'gen_education']
            }
          }
        ]
      },
      {
        path: 'Messages',
        name: 'Dashboard.Messages',
        components: {
          auxiliary: TheYourCareTeam,
          default: TheDashboardMessages
        },
        meta: {
          title: ['messages']
        },
        props: true
      }
    ]
  },
  {
    path: '/Settings',
    component: TheDashboard,
    children: [
      {
        path: '/Settings',
        name: 'Settings',
        components: {
          auxiliary: TheMobileAppPromo,
          default: TheSettingsMenu
        },
        meta: {
          title: ['settings']
        },
        props: true
      }
    ]
  },
  {
    path: '/Settings/Support',
    name: 'Settings.Support',
    component: TheSupport,
    meta: {
      title: ['settings_help_support', 'settings']
    }
  },
  {
    path: '/Settings/About',
    name: 'Settings.About',
    component: TheAbout,
    meta: {
      title: ['settings_about', 'settings']
    }
  },
  {
    path: '/Settings/LegalAndPrivacy',
    name: 'Settings.LegalAndPrivacy',
    component: TheLegalAndPrivacy,
    meta: {
      title: ['settings_legal_and_privacy', 'settings']
    }
  },
  {
    path: '/Settings/SafetyInformation',
    name: 'Settings.SafetyInformation',
    component: TheSettingsSafetyInformation,
    meta: {
      title: ['routine_safety', 'settings_legal_and_privacy', 'settings']
    }
  },
  {
    path: '/Settings/PrivacyPolicy',
    name: 'Settings.PrivacyPolicy',
    component: TheSettingsPrivacyPolicy,
    meta: {
      title: ['activate_privacy_policy', 'settings_legal_and_privacy', 'settings']
    }
  },
  {
    path: '/Settings/EULA',
    name: 'Settings.EULA',
    component: TheSettingsEULA,
    meta: {
      title: ['activate_eula', 'settings_legal_and_privacy', 'settings']
    }
  },
  {
    path: '/Settings/ChangePassword',
    name: 'Settings.ChangePassword',
    component: TheChangePassword,
    meta: {
      title: ['password_change_password_capitalized', 'settings']
    }
  },
  {
    path: '/Education/Technology/ROSAKnee',
    name: 'Education.Technology.ROSAKnee',
    component: TheROSAKneeEducationLibrary,
    meta: {
      title: ['rosa_education_rosa_knee_system_ios', 'pwe_technology_library', 'gen_education']
    }
  },
  {
    path: '/Education/RosaKnee/Epub/:itemId',
    name: 'Education.RosaKnee.Epub',
    component: Education,
    props: true
  },
  {
    path: '/Education/RosaKnee/Video/:itemId',
    name: 'Education.RosaKnee.Video',
    component: LibraryVideoItem,
    props: true
  },
  {
    path: '/Education/RosaKnee/Html/:itemId/:title?/:heading?',
    name: 'Education.RosaKnee.Html',
    component: LibraryHtmlItem,
    props: (route) => ({ ...route.params, title: i18n.global.t('rosa_education_rosa_knee_system_ios') })
  },
  {
    path: '/Education/PreSurgery/:itemId',
    name: 'Education.PreSurgery',
    component: Education,
    props: (route: any) => ({ ...route.params, ...{ itemId: Number.parseInt(route.params.itemId, 10), redirectPage: 'PreSurgery' } })
  },
  {
    path: '/Education/PostSurgery/:itemId',
    name: 'Education.PostSurgery',
    component: Education,
    props: (route: any) => ({ ...route.params, ...{ itemId: Number.parseInt(route.params.itemId, 10), redirectPage: 'PostSurgery' } })
  },
  {
    path: '/Education/:itemId/:tickSignature',
    name: 'Education.Education',
    component: Education,
    props: (route: any) => ({ ...route.params, ...{ itemId: Number.parseInt(route.params.itemId, 10) } }),
    meta: {
      title: ['gen_education']
    }
  },
  {
    path: '/Routine/:itemId/:tickSignature',
    name: 'Routine.Routine',
    component: Routine,
    props: true,
    meta: {
      title: ['gen_routine']
    }
  },
  {
    path: '/Routine/Exercise/:itemId/:exerciseId/:tickSignature',
    name: 'Routine.Exercise',
    component: Exercise,
    props: true,
    meta: {
      title: ['gen_exercise']
    }
  },
  {
    path: '/Routine/Rate/:itemId/:tickSignature',
    name: 'Routine.Rate',
    component: RateRoutine,
    props: true,
    meta: {
      title: ['routine_rate']
    }
  },
  {
    path: '/Routine/Completed/:completed/:rating',
    name: 'Routine.Completed',
    component: CompletedRoutine,
    props: true,
    meta: {
      title: ['watch_todo_completed_ios']
    }
  },
  {
    path: '/DailyWalkingGoal',
    name: 'DailyWalkingGoal',
    component: TheDailyWalkingGoal,
    meta: {
      title: ['gait_walking_progress']
    }
  },
  // This is just a placeholder for the Pain Check In Survey to represent the action of the Pain Rating button.
  {
    path: '/PainRating',
    name: 'PainRating',
    component: ThePainRating,
    meta: {
      policy: AuthPolicyEnum.FullTrustPatient,
      title: ['pain_rating_title']
    }
  },
  {
    path: '/Survey/:surveyId/:tickSignature?/:pageNumber?',
    name: 'Survey',
    props: (route: any) => ({ ...route.params, ...{ surveyId: Number.parseInt(route.params.surveyId, 10) } }),
    component: TheSurveyContainer,
    meta: {
      policy: AuthPolicyEnum.FullTrustPatient,
      title: ['gen_survey']
    }
  }
];

export default routes;
