<script lang="ts" setup>
import {defineProps} from 'vue';
import Svg from '@/components/Svg.vue';
import {mymobilityWordmark} from '@/assets';

const props = defineProps({
  authenticated: {
    type: Boolean
  },
  /* Controls the width of the mymobility Wordmark. Options are:
   * "110" - sets the width to 110px wide.
   * "165" - sets the width to 165px wide.
   */
  width: {
    type: String
  }
});
</script>

<template>
  <component class="wordmark"
             :is="props.authenticated ? 'router-link' : 'div'"
             :to="props.authenticated ? { name: 'Dashboard.Home' } : null">
    <Svg :class="`wordmark-w-${props.width}`"
         :vector-paths="mymobilityWordmark.vectorPaths"
         :view-box="mymobilityWordmark.viewBox" />
  </component>
</template>

<style lang="scss" scoped>
.wordmark { // <a> or <div>
  display: block;
  line-height: 0;
  color: currentColor;

  &:focus,
  &:hover {
    
    &:not(.q-btn):not(.q-item) {
      text-decoration: none;
    }
  }
  
  &-w-110 {
    width: 110px;
  }
  
  &-w-165 {
    width: 165px;
  }
}
</style>