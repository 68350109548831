<script lang="ts" setup>
import { onMounted } from 'vue';

import { handleApiRequest } from 'src/api/handleApiRequest';
import { getPatientInterrupts } from 'src/api/endpoints/userWeb';
import { useDashboardStore } from 'src/store/dashboardModule';
import { useQuasar } from 'quasar';
import { useRouter } from 'vue-router';
import { resetProcedureRelatedState } from '@/services/userService';
import { loadPatientInfoAndTasks } from 'src/pages/TheDataHydrator';
import { useMessagesStore, useSessionStore } from 'src/store';

const dashboardStore = useDashboardStore();
const $q = useQuasar()
const router = useRouter()

const sessionStore = useSessionStore();
const messagesStore = useMessagesStore();

const props = defineProps({
  reload: {
    type: String,
    default: 'true'
  }
})

onMounted(async () => {
  if(props.reload === 'true') {
    // When procedure is changed we reset procedure data
    resetProcedureRelatedState()
  } else {
    // Reload Interrupts for later usages
    const interruptsResult =  await handleApiRequest(() => getPatientInterrupts(), null, $q);;
    if (interruptsResult) {
      dashboardStore.loadPatientInterrupts(interruptsResult.result.interrupts);
    }
  }

  $q.loading.show()

  try {

    await loadPatientInfoAndTasks(sessionStore, messagesStore, dashboardStore, $q);

    $q.loading.hide()

    // Has interrupts.
    if (dashboardStore.patientInterrupts.length) {

      // Get the first ("next") interrupt in the list of interrupts.
      const nextInterrupt = dashboardStore.patientInterrupts[0];

      // About Your Data interrupt.
      if (nextInterrupt.type === 'careteam-data-share-consent') {
        router.replace({ name: 'AboutYourData' });
        return;

        // Pain & Opioid interrupt(s).
      } else if (nextInterrupt.type === 'Pain_and_Opioid_Pre_Op' || nextInterrupt.type === 'Pain_and_Opioid_Post_Op') {
        router.replace({ name: 'PainRating' });
        return;
      }
    }

    // Doesn't have interrupts.
    router.replace({ name: 'Dashboard.Home', params: { reload: props.reload } });
  } catch (error) {
    $q.loading.hide()
  }
})
</script>

<template>
  <div></div>
</template>