import {
  getAlerts,
  getPatientInterrupts,
  getPatientProcedures,
  getPatientProfileData,
  getTasks
} from 'src/api/endpoints/userWeb';
import { getPatientStatisticSummaries } from 'src/api/endpoints/statisticsWeb';
import { getMessages } from 'src/api/endpoints/messagesWeb';
import { getPatientConfig } from 'src/api/endpoints/patientWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';
import moment from 'momentCultured';
import { Task } from 'src/types/webContracts';
import { factorProcedures } from 'src/utils/procedureUtils';

export const loadPatientInfoAndTasks = async (sessionStore, messagesStore, dashboardStore, $q) => {
  document.querySelectorAll('html')[0].scrollTop = 0;

  sessionStore.setIsHeaderVisible(true);
  sessionStore.setIsFooterVisible(true);

  //this could hit because of a login or a relogin
  if (!dashboardStore.patient && !dashboardStore.isLoading) {
    if (!dashboardStore.isLoading) {
      dashboardStore.setIsLoading(true);
      messagesStore.setIsLoading(true);

      const [
        patientResponse,
        patientConfigResponse,
        alertResponse,
        taskResponse,
        messagesResponse,
        statsResponse,
        getPatientProcRsp,
        getPatientInterruptsRsp,
      ] = await Promise.all([
        handleApiRequest(() => getPatientProfileData(true), null, $q),
        handleApiRequest(() => getPatientConfig(), null, $q),
        handleApiRequest(() => getAlerts(), null, $q),
        handleApiRequest(() => getTasks(), null, $q),
        handleApiRequest(() => getMessages(), null, $q),
        handleApiRequest(() => getPatientStatisticSummaries('Wearable'), null, $q),
        handleApiRequest(() => getPatientProcedures(), null, $q),
        handleApiRequest(() => getPatientInterrupts(), null, $q)
      ]);

      if (patientResponse) {
        dashboardStore.loadPatient(patientResponse.result);
      }

      if (patientConfigResponse) {
        dashboardStore.loadPatientConfig(patientConfigResponse.result);
      }

      if (alertResponse) {
        dashboardStore.loadAlerts(alertResponse.result);
      }

      const tasks = new Array<Task>();

      if (taskResponse) {
        const loadTasks = [...taskResponse.result.tasks];

        tasks.push(...loadTasks);
        dashboardStore.loadNextTaskDates(taskResponse.result);
      }

      dashboardStore.loadTasks(tasks);

      if (messagesResponse) {
        messagesStore.setUserId(sessionStore.userId);
        messagesStore.loadMessages(messagesResponse.result);
      }
      messagesStore.setIsLoading(false);

      if (statsResponse) {
        dashboardStore.loadPatientWearables(statsResponse.result);
      }

      if (getPatientProcRsp?.result) {
        const episodesOfCare = getPatientProcRsp.result.episodesOfCare;

        factorProcedures(episodesOfCare);

        sessionStore.loadProcedures(episodesOfCare);
      }

      if (getPatientInterruptsRsp) {
        dashboardStore.loadPatientInterrupts(getPatientInterruptsRsp.result.interrupts);
      }

      dashboardStore.setIsLoading(false);
    }
  }
}

// const unreadMessagesreCount = computed(() => messagesStore.unreadMessagesCount);

const taskCoolDownTimer = (dashboardStore, $q) => {
  const now = moment(),
    released = moment().add(60 - dashboardStore.mostRecentCompletedTime, 'minute')

  let runningTimer = 0;

  clearTimeout(runningTimer);
  if (now.isBefore(released)) {
    runningTimer = window.setTimeout(async function () {
      refreshTasks(dashboardStore, $q);
    }, (60 - dashboardStore.mostRecentCompletedTime) * 60 * 1000);
  }
}

const refreshTasks = async (dashboardStore, $q) => {
  dashboardStore.setIsLoading(true);
  const taskResponse = await handleApiRequest(() => getTasks(), null, $q);

  if (taskResponse) {
    const loadTasks = [...taskResponse.result.tasks];

    dashboardStore.loadTasks(loadTasks);
    dashboardStore.loadNextTaskDates(taskResponse.result);
  }
  dashboardStore.setIsLoading(false);
}